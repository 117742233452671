<script>
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Preloader component
 */
export default {
  data() {
    return {
      refresh_token: this.$route.query.refresh_token,
      kd_izin: this.$route.query.kd_izin,
      access_token: this.$route.query["access-token"],
    };
  },
  props: {
    loading: Boolean,
  },
  mounted() {
    let self = this;
    console.log(
      self.refresh_token + " " + self.kd_izin + " " + self.access_token
    );
    axios
      .get(process.env.VUE_APP_BACKEND_URL_VERSION + "oss/loginSSO", {
        params: {
          refresh_token: self.refresh_token,
          kd_izin: self.kd_izin,
          access_token: self.access_token,
        },
        headers: {
          Accept: "application/json",
        },
      })
      .then(function (response) {
        if (response.data.meta.code == 200) {
          if (response.data.data.status == 200) {
            var token = response.data.data.access_token;
            localStorage.access_token = token;
            localStorage.session = JSON.stringify(response.data.data.user);
            localStorage.session_nama = response.data.data.user.name;
            localStorage.session_nib = response.data.data.user.nib;
            localStorage.kbli = null;
            localStorage.reload_already = "no";
            localStorage.dateLogin = Date.now();
            localStorage.roles = response.data.data.user.role_slug;
            self.$router.push({ name: "pemohon-dashboard" });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.data.message,
            });
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terjadi Kesalahan, Coba lagi nanti",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<template>
  <div :class="{ 'is-loading': loading }">
    <div class="preloader-component">
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        "
      >
        <img src="@/assets/images/logo-kementan.png" alt height="100" />
        <br />
        <br />
        <h3 class="ms-1"><b>Kementerian Pertanian</b></h3>
        <br />
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
        <br />
        <h4><b>Mohon Tunggu Sebentar</b></h4>
      </div>
    </div>
    <slot />
  </div>
</template>
